@import "./src/mixins";

.part-wrapper {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  position: relative;


  &:before {
    position: absolute;
    height: 100%;
    width: 50%;
    background: var(--primary-color);
    content: "\00a0";
    z-index: -1;
  }
  &:after {
    position: absolute;
    height: 100%;
    width: 50%;
    right: 0;
    background: white;
    content: "\00a0";
    z-index: -1;
  }
  &.reversed:before {
    right: 0;
  }
  &.reversed:after {
    left: 0;
    right: auto;
  }


  @include breakpoint('small only') {
    &:not(.splash) {
      display: grid;
      grid-template-rows: repeat(2, auto);

      &:before {
        background: var(--white);
      }

      &:not(.reversed):not(.dark) {
        > :nth-child(1) {
          order: 2;
        }
        > :nth-child(2) {
          background: var(--white);
        }
      }
      &.reversed > :nth-child(2) {
        order: 2;
      }
      > :nth-child(1), > :nth-child(2) {
        width: 100%;
        grid-column: 1 / 3;
        grid-row: 1 / 3;

        &.content-container {
          z-index: 1;

          &:not(.dark) {
            background: none !important;
          }
        }

        .part-content {
          padding: 25px;

          h1 {
            font-size: 2rem;
          }
        }
      }
      &:not(.dark) {
        > :nth-child(1), > :nth-child(2) {
          &.content-container {
            h1, p {
              position: relative;
              padding: 10px;

              &:before {
                content: "\00a0";
                position: absolute;
                background: var(--white);
                inset: 0;
                z-index: -1;
                width: calc(100vw - 25px);
              }
            }
            h1:before {
              left: -25px;
            }
          }
        }
      }
    }
  }

  > * {
    flex-grow: 1;
    display: flex;
    align-content: center;
    align-items: center;
    width: 50%;
    position: relative;

    > * {
      max-width: 100%;
      max-height: 100%;
    }
  }
  > .left-side {
    justify-content: end;
  }
  > .right-side {
    justify-content: start;
  }
  > .left-side, > .right-side {
    > video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .part-content {
      padding: 100px 5vw;

      h1 {
        font-size: 3em;
        font-weight: normal;
        color: var(--primary-color);

        b {
          font-weight: normal;
          color: var(--secondary-color);
        }
      }
      p {
        color: var(--secondary-color);
      }
    }
  }
  &.dark {
    background-color: var(--secondary-color);

    .left-side, .right-side {
      justify-content: center;

      .part-content {
        h1, p {
          color: var(--white);
        }
      }
    }
  }

  .header-bar-background-wrapper {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    top: 0;
    display: block;
    pointer-events: none;

    @include breakpoint('small only') {
      display: none;
    }

    .header-bar-background {
      position: sticky;
      top: 0;
      height: 76px;
      width: 50vw;
      z-index: 1;
      transform-origin: top;
    }

    &.primary .header-bar-background {
      background: var(--primary-color);
    }

    &.secondary .header-bar-background {
      background: var(--secondary-color);
    }
  }
}
