@import "../../mixins";

.header-bar-wrapper {
  position: fixed;
  height: 76px;
  width: 100vw;
  display: flex;
  padding: 22px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  z-index: 10;

  &:before {
    display: none;
    position: absolute;
    width: 50%;
    height: calc(100% + 1px);
    left: 0;
    top: 0;
    background: var(--primary-color);
    content: "\00a0";
    z-index: -1;
  }

  .header-bar-left {
    display: inherit;
    gap: inherit;
    align-content: center;
    align-items: center;
  }

  img {
    height: 23px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0 25px;
    display: flex;
    gap: 25px;

    &.buttons {
      border-left: solid 1px var(--white);
    }

    li {
      a {
        color: var(--white);
        text-decoration: none;
        font-family: Exodus, serif;
        font-size: 20px;
      }
    }

    &.icons {
      position: relative;

      .balloon {
        position: absolute;
        background: var(--white);
        margin-top: 20px;
        border-radius: 20px;
        box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.5);
        padding: 25px;
        right: 0;
        border: solid 1px rgba(0, 0, 0, 0.25);
        //left: 50%;
        transition: all 250ms ease;

        &:not(.show) {
          pointer-events: none;
          opacity: 0;
        }

        h4 {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }
    li.mobile-only {
      display: none;

      @include breakpoint('small only') {
        display: block;
      }
    }
  }


  @include breakpoint('small only') {
    padding-right: 15px;

    .header-bar-left ul.buttons {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      height: 50vh;
      display: flex;
      border: none;
      padding: 0;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      width: 0;
      transition: all 250ms ease;

      &:before {
        position: fixed;
        inset: 0;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(2px);
        z-index: -1;
        content: "\00a0";
        transition: inherit;
        width: 0;
      }

      li {
        width: 100vw;
        display: block;
        padding: 0 50px;
        text-align: center;

        a {
          color: var(--primary-color);
          font-size: 3rem;
        }
      }
    }
    ul.icons {
      padding: 0;
      gap: 5px;

      li {
        a {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:focus {
            outline: none;
          }
        }
      }
    }
    .menu-button {
      width: 25px;
      height: 25px;
      position: relative;
      display: block;

      div {
        height: 4px;
        width: 100%;
        position: absolute;
        display: block;
        background-color: var(--primary-color);
        z-index: 10;
        transition: all 250ms ease;

        &:before {
          content: "\00a0";
        }
        &:nth-child(2) {
          top: calc(50% - 2px);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }

      &.open {
        div {
          &:nth-child(1) {
            transform: scaleX(0);
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            bottom: calc(50% - 2px);
            transform: rotate(-45deg);
          }
        }
      }
    }

    &.menu-open {
      .header-bar-left {
        ul.buttons, ul.buttons:before {
          width: 100vw;
        }
      }
    }
  }
}
