@font-face {
  font-family: Exodus;
  src: url("/resources/fonts/ExodusDemo-Regular.otf") format("opentype");
}
:root {
  --primary-color: #7A7256;
  --secondary-color: #101820;
  --alert-color: #ee0a49;
  --white: white;
}
*, *:before, *:after {
  box-sizing: border-box;
}
h1 {
  font-family: Exodus, serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
