@import "../../mixins";

.field {
  transition: margin 250ms ease;
  margin-top: 25px;
  position: relative;


  &:before {
    position: absolute;
    content: "\00a0";
    background: transparent;
    width: 10px;
    height: 10px;
    left: -30px;
    top: 15px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    transform: scaleX(.7);
    opacity: 0;
    transition: all 250ms ease;
  }
  & + .field {
    margin-top: 0;
  }
  &.invalid {
    &:before {
      left: -15px;
      opacity: 1;
    }
    input {
      text-decoration: line-through;
    }
  }
  &.checkbox {
    &:before {
      top: 5px;
    }
  }

  .field-content {
    > input:not([type="button"]):not([type="submit"]):not([type="checkbox"]), textarea {
      line-height: 39px;
      display: block;
      padding: 0;
      width: 100%;
      background-color: transparent;
      background-image: none !important;
      border: none;
      border-bottom: solid 1px var(--white);
      color: inherit;
      font-size: 16px;
      box-shadow: none !important;
      border-top: none;

      &:focus, &:not([value=""]):not([data-value=""]) {
        outline: 0;

        & + label {
          padding-left: 0;
        }
        &:not(textarea) + label {
          transform: translateY(-250%) scale(.8);

          @media not all and (min-resolution: .001dpcm) {
            @supports (-webkit-appearance:none) {
              transform: translateY(-260%) scale(.8);
            }
          }
        }
      }
    }
    > input[type="checkbox"] {
      display: none;

      & + label {
        transform: none;
        margin-left: 30px;
        pointer-events: all;
        position: relative;
        margin-top: 10px;
        margin-bottom: 30px;

        &:before, &:after {
          position: absolute;
          left: -30px;
          height: 20px;
          width: 20px;
          text-align: center;
        }
        &:before {
          content: "\00a0";
          border: solid 2px var(--white);
        }
        &:after {
          content: "✓";
          transition: all 250ms ease;
        }
      }
      &:active + label {
        transform: scale(1.1);
      }
      &:not(:checked) + label:after {
        transform: scale(0);
      }
    }
    > textarea {
      resize: none;
      line-height: inherit;
      height: 39px;
      font-family: inherit;
      font-size: 12px;
      margin-top: 10px;
      transition: all 250ms ease;

      &:focus, &:not([data-value=""]) {
        height: 100px;

        & + label {
          transform: translateY(-120px) scale(.8);
        }
      }
    }
    > label {
      display: block;
      transform: translateY(-34px);
      font-size: 14px;
      transition: all 250ms ease;
      transform-origin: top left;
      opacity: 0.8;
      user-select: none;
      pointer-events: none;

      @include breakpoint('medium') {
        font-size: 16px;
      }



      .required-indicator {
        position: relative;
        z-index: 1;
        margin-left: 5px;
        transition: all 250ms ease;
        display: inline-block;

        &:not(.show) {
          transform: translateX(100%);
          opacity: 0;
        }

        &:before {
          color: var(--alert-color);
          position: absolute;
          content: "*";
          border-radius: 50%;
          top: 0;
          left: 0;
          padding-top: 4px;
          line-height: 6px;
          overflow: hidden;
          text-align: center;
          width: 10px;
          z-index: -1;
          transform: translateX(-1px) translateY(2px);
        }
      }
    }
  }
}
