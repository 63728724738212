.parallax-wrapper {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  > * {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  @for $i from 1 through 10 {
    &[data-distance="#{$i * 100}"] > * {
      height: calc(100% + #{$i} * 100px);
      margin-top: calc(#{$i} * 100px * -1);
    }
  }
}
