@import "../../mixins";

.home-wrapper {
  .splash {
    &:after {
      display: none;
    }
    img {
      position: fixed;
      z-index: -1;

      @include breakpoint('small only') {
        max-width: 40vw;
      }
    }
    .left-side {
      padding-right: 5vw;


      @include breakpoint('small only') {
        img {
          max-width: 40vw;
        }
      }
    }
    .right-side {
      padding-left: 5vw;
    }
    .left-side:before, .right-side:before {
      content: "\00a0";
      width: 2px;
      height: 220px;
      position: fixed;
      top: calc(50% - 110px);
      z-index: -1;
    }
    .left-side:before {
      right: 50vw;
      background: white;
    }
    .right-side:before {
      left: 50vw;
      background: var(--primary-color);
    }
    .left-side > *, .left-side:before {
      opacity: 0;
      animation: 250ms slide-left-splash-in 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }
    .right-side > *, .right-side:before {
      opacity: 0;
      animation: 250ms slide-right-splash-in 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }
  }

  .with-vignette {
    position: relative;

    &:before {
      position: absolute;
      inset: 0;

      box-shadow: 0 0 100vw rgba(0,0,0,1) inset;
      content: "\00a0";
    }
  }


}
@keyframes slide-left-splash-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes slide-right-splash-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
