@mixin breakpoint($size) {
  @if($size == 'small only') {
    @media screen and (max-width: 39.9375em) {
      @content
    }
  }
  @if($size == 'medium') {
    @media screen and (min-width: 40em) {
      @content
    }
  }
  @if($size == 'medium only') {
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      @content
    }
  }
  @if($size == 'large') {
    @media screen and (min-width: 64em) {
      @content
    }
  }
  @if($size == 'large only') {
    @media screen and (min-width: 64em) and (max-width: 74.9375em) {
      @content
    }
  }

}
