button.button {
  border: none;
  height: 50px;
  padding: 0 25px;
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;

  &.variant-dark {
    background: transparent;
    color: var(--white);
    border: solid 2px var(--white);
  }

}
